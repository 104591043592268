import React from "react";
import flixIcon from "@assets/flix-package-icons/flix-icon.png";

interface CoinCardProps {
  flax: any;
  price: any;
  flixHistoryType?: string;
  userName: any;
}

const FlixCards: React.FC<CoinCardProps> = ({
  flax,
  price,
  flixHistoryType,
  userName = "",
}) => {
  return (
    <article className="flex flex-col md:flex-row  px-2  gap-4 w-full  bg-bgLightPink  rounded-lg">
      <section className="flex gap-4 w-full md:w-2/3">
        <figure className="flex-shrink-0">
          <img
            className="rounded-full w-8 h-8 md:w-8 md:h-8 object-cover"
            alt="user"
            src={flixIcon}
          />
        </figure>
        <div className="flex flex-col gap-3 flex-grow ">
          <div className="inline-flex flex-grow  px-2  rounded-lg text-xs font-semibold items-center  ">
            <div>
              {flixHistoryType && flixHistoryType === "others"
                ? `Purchased For ${userName}  `
                : ""}
            </div>
            <div className="bg-yellow-300 p-1 px-2 rounded-lg ml-3">
              {flax} FLiX
            </div>
          </div>
          <div className="flex flex-wrap flex-grow gap-1">
            <span className="text-primary text-xs ml-2  ">AED {price}</span>
          </div>
        </div>
      </section>
    </article>
  );
};

export default FlixCards;
